import { action, computed, event, observable, persist, store } from '~/common/mobx.decorator'
import { appStore, authStore, notifyStore, routerStore } from '~/stores'
import {
	ActivationNavSvg,
	ChangePassSvg,
	ContactUsSvg,
	CredentialsSvg,
	FindFullSvg,
	FindSvg,
	HomeFullSvg,
	HomeSvg,
	MyJobsFullSvg,
	MyJobsSvg,
	SettingsSvg,
	UserPlusSvg,
} from '~/components/icons'
import { EVENTS, PATHS } from '~/common/constants'
import {
	apolloClient,
	GET_COMPANY_INFO_QUERY,
	GET_WORKER_AVATAR,
	GET_WORKER_BASIC_INFO_QUERY,
	GET_WORKER_DETAIL_QUERY,
} from '~/common/apollo'
import { eventBus } from 'mobx-event-bus2'

import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'
import { PersonalCardSvg } from '../../components/icons'
import i18next from 'i18next'

export const PATH_NAME = window.location.path

const FEATURE_TYPE = {
	activated: 'activated',
	nonActivated: 'nonActivated',
	public: 'public',
	common: 'common',
}

@store()
class CareStore {
	@observable showProfileSidebar
	@observable showOnboardReminderCard = true
	@observable customHeader = {}
	@observable worker = {}
	@observable workerBasicInfo = {}
	@observable companyInfo = {}
	@observable companyConfig = {}
	@observable workerConfigInfo = {}
	@persist @observable workerAvatar = null
	@observable workerAvatarId = null

	@computed
	get privacyPolicyUrl() {
		return 'https://www.loyalsource.com/privacy-policy/'
	}

	@computed
	get termsOfUseUrl() {
		return appStore.config?.companyConfigs?.termUrl
	}

	@computed
	get facebookUrl() {
		return this.compamyInfo.facebookUrl
	}

	@computed
	get instagramUrl() {
		return this.compamyInfo.instagramUrl
	}

	@computed
	get twitterUrl() {
		return this.compamyInfo.twitterUrl
	}

	@computed
	get linkedinUrl() {
		return this.compamyInfo.linkedInUrl
	}

	@computed
	get noSms() {
		return this.worker.noSms
	}

	@computed
	get enableSmsSender() {
		return this.worker?.enableSmsSender
	}

	@computed
	get messagingServiceSender() {
		return this.companyConfig?.messagingServiceSender
	}

	@computed
	get privacyPolicyContent() {
		return this.companyConfig?.privacyPolicyContent
	}

	@computed
	get features() {
		const publicMenu = this.getFeature(FEATURE_TYPE.public)
		const activatedMenu = this.getFeature(FEATURE_TYPE.activated)
		const nonActivatedMenu = this.getFeature(FEATURE_TYPE.nonActivated)

		return authStore.activated ? [...activatedMenu, ...publicMenu] : [...nonActivatedMenu, ...publicMenu]
	}

	@computed
	get allFeatures() {
		const common = this.getFeature(FEATURE_TYPE.common)
		const allFeature = [...this.features, ...common]

		return allFeature?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get activeFeature() {
		return this.features?.find((feature) => feature.path === window.location.pathname)
	}

	@computed
	get code() {
		return this.getActiveFeature()?.code
	}

	@computed
	get name() {
		return this.getActiveFeature()?.name || ''
	}

	@computed
	get useBanner() {
		return this.getActiveFeature()?.useBanner
	}

	@computed
	get showOnboardReminder() {
		return this.getActiveFeature()?.showOnboardReminder && this.showOnboardReminderCard
	}

	@computed
	get getReferalRewardContent() {
		return this.companyConfig.referalRewardContent
	}

	@computed
	get sidebarMenu() {
		return this.features?.filter((feature) => feature.showSideBar)
	}

	@computed
	get dropDownMenu() {
		return this.features?.filter((feature) => feature.showDropdown)
	}

	@computed
	get appbarMenu() {
		return this.features?.filter((feature) => feature.showAppBar)
	}

	@computed
	get compamyInfo() {
		return this.company
	}

	@computed
	get companyName() {
		return this.compamyInfo?.name
	}

	@action
	getActiveFeature = () => {
		return this.features?.find((feature) => feature.path === routerStore.basePath)
	}

	@action
	fetchWorkerDetail = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_DETAIL_QUERY,
			variables: { id: authStore.id },
		})
		this.worker = data.worker
	}

	@action
	fetchWorkerBasicInfo = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_BASIC_INFO_QUERY,
			variables: { id: authStore.id },
		})
		this.workerBasicInfo = data.worker
		authStore.setWorkAuthorized(this.workerBasicInfo.workAuthorizedAt)

		return data?.worker
	}

	@action
	fetchWorkerAvatar = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_AVATAR,
			variables: { workerId: authStore.id },
		})
		this.workerAvatar = data?.worker?.avatar?.file?.thumbnails?.s_100x100
		this.workerAvatarId = data?.worker?.avatar?.id ?? null
	}

	@action
	fetchCompanyInfo = async () => {
		const { data } = await apolloClient.query({ query: GET_COMPANY_INFO_QUERY })
		this.companyInfo = data?.appInit
		this.companyConfig = {
			twitterUrl: data?.appInit?.tenantConfig?.twitterUrl,
			facebookUrl: data?.appInit?.tenantConfig?.facebookUrl,
			linkedInUrl: data?.appInit?.tenantConfig?.linkedInUrl,
			instagramUrl: data?.appInit?.tenantConfig?.instagramUrl,
			tiktokUrl: data?.appInit?.tenantConfig?.tiktokUrl,
			youtubeUrl: data?.appInit?.tenantConfig?.youtubeUrl,
		}
	}

	getFeature = (type) => {
		const activatedMenu = [
			{
				code: 'home',
				name: 'HOME',
				path: PATHS.care.home,
				icon: HomeSvg,
				iconFilled: HomeFullSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				eventName: 'home_btn',
				useBanner: false,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickHomeNavBtn,
			},
			{
				code: 'news-feed',
				name: 'NEWS_FEED',
				path: PATHS.care.news_feed,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				showOnboardReminder: true,
			},
			{
				code: 'resources',
				name: 'RESOURCE',
				path: PATHS.care.resources,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				showOnboardReminder: true,
			},
			{
				code: 'job-results',
				name: 'SEARCH_JOBS',
				path: PATHS.care.search,
				icon: FindSvg,
				iconFilled: FindFullSvg,
				auth: true,
				showAppBar: true,
				eventId: EVENT_CLICK_ID.clickSearchJobsNavBtn,
				showSideBar: true,
				eventName: 'search-jobs_btn',
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.filter,
				icon: FindSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'edit-filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.edit_filter,
				icon: FindSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'job-results',
				name: 'SEARCH_RESULTS',
				path: PATHS.care.search,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'my-jobs',
				name: 'MY_JOBS',
				path: PATHS.care.my_jobs,
				icon: MyJobsSvg,
				iconFilled: MyJobsFullSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				eventName: 'my-jobs_btn',
				isDisabled: false,
				useBanner: true,
				eventId: EVENT_CLICK_ID.clickMyJobsNavBtn,
				bannerUrl: PATHS.banners.third,
			},
			{
				code: 'profile',
				name: 'PROFILE',
				path: PATHS.care.profile,
				icon: PersonalCardSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.third,
			},
			{
				code: 'credentials',
				name: 'CREDENTIALS',
				path: PATHS.care.credentials,
				icon: CredentialsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'referrer',
				name: 'REFERRER',
				icon: UserPlusSvg,
				path: PATHS.care.referrer,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			// {
			// 	code: 'referral-traveller',
			// 	name: 'REFER_TRAVELER',
			// 	icon: UserPlusSvg,
			// 	path: PATHS.care.refer_traveler,
			// 	showAppBar: false,
			// 	showSideBar: false,
			// 	isDisabled: false,
			// 	showDropdown: true,
			// 	useBanner: false,
			// },
			{
				code: 'contact-us',
				name: 'CONTACT_US',
				icon: ContactUsSvg,
				path: PATHS.care.contact_us,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				useBanner: false,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'change-password',
				name: 'CHANGE_PASSWORD',
				icon: ChangePassSvg,
				path: PATHS.common.change_password,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.third,
			},
			{
				code: 'update-phone',
				name: 'UPDATE_PHONE_NUMBER',
				icon: ChangePassSvg,
				path: PATHS.common.update_phone,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'settings',
				name: 'SETTINGS',
				path: PATHS.care.settings,
				eventId: EVENT_CLICK_ID.clickSettingsNavBtn,
				icon: SettingsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'popular-cities',
				name: 'POPULAR_CITIES',
				path: PATHS.care.popular_cities,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'popular-cities-detail',
				name: 'POPULAR_CITY_JOBS',
				path: PATHS.care.popular_cities_detail,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBreadcrumb: true,
			},
			{
				code: 'explore-matches',
				name: 'EXPLORE_MATCHES',
				path: PATHS.care.suggested_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
				useBreadcrumb: true,
			},
			{
				code: 'urgent-needs',
				name: 'HOT_JOBS',
				path: PATHS.care.urgent_needs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			{
				code: 'trending-jobs',
				name: 'TRENDING_JOBS',
				path: PATHS.care.trending_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'job-alerts',
				name: 'NOTIFICATIONS',
				path: PATHS.care.job_alerts,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
			},

			{
				code: 'last-viewed',
				name: 'LAST_VIEWED',
				path: PATHS.care.last_viewed,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			// {
			// 	code: 'last-searches',
			// 	name: 'LAST_SEARCHES',
			// 	path: PATHS.care.last_searches,
			// 	icon: MyJobsSvg,
			// 	auth: true,
			// 	showAppBar: false,
			// 	showSideBar: false,
			// 	isDisabled: false,
			// 	useBanner: false,
			// 	bannerUrl: PATHS.banners.first,
			// 	useBreadcrumb: true,
			// },
			{
				code: 'bookmark',
				name: 'BOOKMARKED_JOBS',
				path: PATHS.care.bookmark,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'privacy-policy',
				auth: true,
				path: PATHS.care.privacy_policy,
				showAppBar: false,
				showSideBar: false,
			},

			{
				code: 'notification',
				name: 'NOTIFICATIONS',
				path: PATHS.care.notification,
				icon: 'icon',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: false,
			},
			{
				code: 'messages',
				name: 'MESSAGES',
				path: PATHS.care.messages,
				icon: 'icon',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: false,
				eventId: EVENT_CLICK_ID.clickMessageBtn,
			},

			{
				code: 'invite-friend',
				name: 'INVITE_YOUR_FRIENDS',
				path: PATHS.care.invite_friend,
				icon: 'icon',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: false,
			},
		].filter(Boolean)

		const nonActivatedMenu = [
			{
				code: 'home',
				name: 'HOME',
				path: PATHS.care.home,
				icon: HomeSvg,
				iconFilled: HomeFullSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				eventName: 'home_btn',
				useBanner: false,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickHomeNavBtn,
			},
			// todo: remove after release profile completion
			{
				code: 'profile',
				name: 'MY_PROFILE',
				path: PATHS.care.profile,
				icon: PersonalCardSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'credentials',
				name: 'CREDENTIALS',
				path: PATHS.care.credentials,
				icon: CredentialsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'resources',
				name: 'RESOURCE',
				path: PATHS.care.resources,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				showOnboardReminder: true,
			},
			{
				code: 'job-alerts',
				name: 'NOTIFICATIONS',
				path: PATHS.care.job_alerts,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
			},

			{
				code: 'update-phone',
				name: 'UPDATE_PHONE_NUMBER',
				icon: ChangePassSvg,
				path: PATHS.common.update_phone,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},

			{
				code: 'job-results',
				name: 'SEARCH_JOBS',
				path: PATHS.care.search,
				icon: FindSvg,
				iconFilled: FindFullSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				eventName: 'search-jobs_btn',
				eventId: EVENT_CLICK_ID.clickSearchJobsNavBtn,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.filter,
				icon: FindSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'job-results',
				name: 'SEARCH_RESULTS',
				path: PATHS.care.search,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'my-jobs',
				name: 'MY_JOBS',
				path: PATHS.care.my_jobs,
				icon: MyJobsSvg,
				iconFilled: MyJobsFullSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				eventName: 'my-jobs_btn',
				isDisabled: false,
				useBanner: true,
				eventId: EVENT_CLICK_ID.clickMyJobsNavBtn,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'edit-filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.edit_filter,
				icon: FindSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'completion_homepage',
				name: 'COMPLETE_YOUR_PROFILE',
				path: PATHS.care.activation,
				icon: ActivationNavSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				showDropdown: false,
				eventId: EVENT_CLICK_ID.clickCompleteYourProfileNavBtn,
				bannerUrl: PATHS.banners.second,
				useBreadcrumb: true,
			},
			{
				code: 'referrer',
				name: 'REFERRER',
				icon: UserPlusSvg,
				path: PATHS.care.referrer,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			// {
			// 	code: 'referral-traveller',
			// 	name: 'REFER_TRAVELER',
			// 	icon: UserPlusSvg,
			// 	path: PATHS.care.refer_traveler,
			// 	showAppBar: false,
			// 	showSideBar: false,
			// 	isDisabled: false,
			// 	showDropdown: true,
			// 	useBanner: false,
			// },
			{
				code: 'contact-us',
				name: 'CONTACT_US',
				icon: ContactUsSvg,
				path: PATHS.care.contact_us,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				useBanner: false,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'popular-cities',
				name: 'POPULAR_CITIES',
				path: PATHS.care.popular_cities,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
				showOnboardReminder: true,
			},
			{
				code: 'popular-cities-detail',
				name: 'POPULAR_CITY_JOBS',
				path: PATHS.care.popular_cities_detail,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBreadcrumb: true,
			},
			{
				code: 'explore-matches',
				name: 'EXPLORE_MATCHES',
				path: PATHS.care.suggested_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
				showOnboardReminder: true,
				useBreadcrumb: true,
			},
			{
				code: 'urgent-needs',
				name: 'HOT_JOBS',
				path: PATHS.care.urgent_needs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
				showOnboardReminder: true,
				useBreadcrumb: true,
			},
			{
				code: 'trending-jobs',
				name: 'TRENDING_JOBS',
				path: PATHS.care.trending_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.second,
				showOnboardReminder: true,
			},
			{
				code: 'last-viewed',
				name: 'LAST_VIEWED',
				path: PATHS.care.last_viewed,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
				useBreadcrumb: true,
			},
			// {
			// 	code: 'last-searches',
			// 	name: 'LAST_SEARCHES',
			// 	path: PATHS.care.last_searches,
			// 	icon: MyJobsSvg,
			// 	auth: true,
			// 	showAppBar: false,
			// 	showSideBar: false,
			// 	isDisabled: false,
			// 	useBanner: false,
			// },
			{
				code: 'bookmark',
				name: 'BOOKMARKED_JOBS',
				path: PATHS.care.bookmark,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'change-password',
				name: 'CHANGE_PASSWORD',
				icon: ChangePassSvg,
				path: PATHS.common.change_password,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				showDropdown: true,
				bannerUrl: PATHS.banners.third,
			},
			{
				code: 'settings',
				name: 'SETTINGS',
				path: PATHS.care.settings,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				icon: SettingsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				showDropdown: false,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: true,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'news-feed',
				name: 'NEWS_FEED',
				path: PATHS.care.news_feed,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				showOnboardReminder: true,
			},
			{
				code: 'notification',
				name: 'NOTIFICATIONS',
				path: PATHS.care.notification,
				icon: 'icon',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: false,
			},
			{
				code: 'messages',
				name: 'MESSAGES',
				path: PATHS.care.messages,
				icon: 'icon',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: false,
				eventId: EVENT_CLICK_ID.clickMessageBtn,
			},

			{
				code: 'invite-friend',
				name: 'INVITE_YOUR_FRIENDS',
				path: PATHS.care.invite_friend,
				icon: 'icon',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useBreadcrumb: false,
			},
		]

		const publicMenu = [
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
		].filter(Boolean)

		const common = [
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useBanner: false,
			},
			{
				code: 'reset',
				name: 'RESET',
				path: PATHS.common.reset,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
			{
				code: 'change-password',
				name: 'REFER_TRAVELER',
				path: PATHS.common.change_password,
				icon: 'user',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				bannerUrl: PATHS.banners.third,
			},
			{
				code: 'errors',
				name: 'ERRORS',
				path: PATHS.common.errors,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
			},
		].filter(Boolean)

		let result = null
		switch (type) {
			case FEATURE_TYPE.activated:
				result = activatedMenu
				break
			case FEATURE_TYPE.nonActivated:
				result = nonActivatedMenu
				break
			case FEATURE_TYPE.public:
				result = publicMenu
				break
			case FEATURE_TYPE.common:
				result = common
				break
			default:
				result = []
				break
		}
		return result
	}

	@action
	toogleProfileSidebar = async () => {
		this.showProfileSidebar = !this.showProfileSidebar
	}

	@action
	toggleOnboardReminder = async () => {
		this.showOnboardReminderCard = !this.showOnboardReminderCard
	}

	@action
	logout = async () => {
		eventBus.post(EVENTS.authStore.logout)
		this.showProfileSidebar = false
	}

	@action
	handleClickChangePassword = async () => {
		this.showProfileSidebar = false
		await routerStore.goPage(PATHS.common.change_password)
	}

	@event(EVENTS.careStore.updateHeader)
	async onUpdateHeader({ payload }) {
		this.customHeader = payload
	}
}

export const careStore = new CareStore()
