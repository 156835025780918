import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import axios from 'axios'
import { debounce, isEmpty, omit } from 'lodash'
import {
	apolloClient,
	GET_PROFILE_BASIC_INFO_QUERY,
	REFRESH_WORKER_TOKEN,
	SIGN_OUT_WORKER_MUTATION,
	VERIFY_EXISTED_EMAIL,
	VERIFY_WORKER_QUERY,
} from '~/common/apollo'
import { COLECTIONS, EVENTS, ONE_DAY, PATHS } from '~/common/constants'
import { captureException, RECAPTCHA_CLIENT_KEY } from '~/common/helpers'
import { action, computed, event, observable, persist, reaction, store } from '~/common/mobx.decorator'
import { notifyStore } from './notify.store'
import { fireStore, routerStore } from '~/stores'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'
import { lastViewedStore } from '~/features/last-viewed/last-viewed.store'
import { getUserTrackingBasicInfor } from '~/common/tracking/event-client.tracking'
import i18next from 'i18next'
import { commonSigninStore } from '~/features/common-signin/common-signin.store'
import { careNotificationStore } from '~/features/care-notification/care-notification.store'

const matchAnonymousEvents = async () => {
	// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
	try {
		const anonymousEvents = await window.host.getUserEvents()
		const anonymousId = await window.host.getId()

		const searchEvents = anonymousEvents
			.filter((event) => event.name === 'ANONYMOUS_SEARCH')
			.map(
				(event) =>
					new events.AnonymousSearchSuccessEvent({
						...event.data,
						metadata: {
							ip: event.ip,
							user_agent: event.userAgent,
							anonymous_id: anonymousId,
						},
					})
			)

		searchEvents.forEach((event) => eventClient.logEvent(event))
		await window.host.archiveUserEvents()
		// IN CASE: You want to clean up all archived events
		// Please call: window.host.cleanUserEvents()
	} catch (error) {
		console.debug(error)
	}
}

class ProfileItem {
	@persist @observable id
	@persist @observable firstName
	@persist @observable lastName
	@persist @observable workAuthorizedAt
	@persist @observable email
}

const DEFAULT_TOKEN_EXPIRE_IN = 30 * 60 * 1000 // calculate in miliseconds

@store({ persist: true })
class AuthStoreCandidate {
	@persist @observable token = ''
	@persist @observable refreshToken = ''
	@persist @observable maintenanceMessage = ''
	@persist @observable expiresAt = 0
	@persist @observable expiresIn = 0
	@persist @observable tokenType
	@persist('object', ProfileItem) @observable profile
	@persist @observable isExistAccount = false
	@persist @observable notificationChannel = ''
	@observable currentJob = null
	@observable isAnonymousLogin = false
	@observable showDialogOtp = false
	@observable phoneNumber = ''
	@observable showChangePasswordDialog = false
	@observable phoneVerifyAt = null
	@observable showResetPassLockAccount = false
	@observable showResetPassLockContent = {}
	@observable ready = false

	@computed
	get recruiter() {
		return this.profile?.recruiter
	}

	@computed
	get authorization() {
		return !this.token || Date.now() > this.expiresAt ? '' : this.token
	}

	@computed
	get features() {
		return [
			'home',
			'completion_homepage',
			'profile',
			'popular-cities',
			'popular-cities-detail',
			'find-job',
			'filter-job',
			'edit-filter-job',
			'job-results',
			'list-jobs',
			'urgent-needs',
			'trending-jobs',
			'explore-matches',
			'my-jobs',
			'jobs',
			'referral',
			'change-password',
			'update-phone',
			'settings',
			'last-viewed',
			// 'last-searches',
			'contact-us',
			'bookmark',
			'job-alerts',
			'privacy-policy',
			'news-feed',
			'credentials',
			'resources',
			'notification',
			'invite-friend',
			'messages',
		]
	}

	@computed
	get id() {
		return this.authorization && this.profile?.id
	}

	@computed
	get isLogin() {
		return this.authorization !== ''
	}

	@computed
	get firstName() {
		return this.profile?.firstName
	}

	@computed
	get workerEmail() {
		return this.profile?.email
	}

	@computed
	get lastName() {
		return this.profile?.lastName
	}

	@computed
	get isExistAccountData() {
		return this.isExistAccount
	}

	@computed
	get fullName() {
		return [this.firstName, this.lastName].join(' ').trim()
	}

	@computed
	get isAnonymous() {
		return this.isAnonymousLogin || this.authorization === ''
	}

	get activated() {
		return !!this.profile?.workAuthorizedAt
	}

	@computed
	get noJobMatches() {
		return this.profile?.noJobMatches
	}

	@computed
	get workerAvatar() {
		return this.profile?.avatar?.thumbnails?.s_100x100
	}

	@computed
	get hasCurrentJob() {
		return !isEmpty(this.currentJob)
	}

	@action
	setMaintenanceMessage = (message) => {
		this.maintenanceMessage = message
	}

	@action
	setWorkAuthorized = (workAuthorizedAt) => {
		this.profile = { ...this.profile, workAuthorizedAt }
	}

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	@action
	changeProfile = async (profile, notificationChannel) => {
		if (
			this.profile &&
			profile &&
			this.profile?.id === profile?.id &&
			profile.workAuthorizedAt === true &&
			this.activated !== profile?.workAuthorizedAt
		) {
			eventClient.logEvent(new events.OnboardingWorkerAuthorizedSuccessEvent())
		}

		this.profile = profile
		if (notificationChannel) this.notificationChannel = notificationChannel
		if (profile) {
			eventClient.setUserId(profile.id)
			eventClient.setUserProperties({
				id: profile.id,
				company_id: process.env.REACT_APP_COMPANY_ID,
				work_authorized: profile.workAuthorizedAt,
			})
			getUserTrackingBasicInfor().then((response) => {
				eventClient.setBasicInfo({
					...response,
					userProperties: {
						id: profile.id,
						company_id: process.env.REACT_APP_COMPANY_ID,
						work_authorized: profile.workAuthorizedAt,
					},
				})
			})
			// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
			await matchAnonymousEvents()
		}
		notifyStore.sendInfo()

		// Update LiveChat Info
		if (window.LC_API && typeof window.LC_API.set_visitor_name === 'function') {
			window.LC_API.set_visitor_name([profile?.firstName, profile?.lastName].join(' ').trim())
			window.LC_API.set_visitor_email(profile?.email)
		}
	}

	@action
	handleAuthAnonymous(value) {
		return (this.isAnonymousLogin = value)
	}

	@action
	changeToken = async (token = {}, duration = ONE_DAY * 30) => {
		const { accessToken, refreshToken, expiresIn, tokenType } = token
		this.tokenType = tokenType
		this.token = accessToken
		this.refreshToken = refreshToken
		this.expiresIn = (expiresIn ?? DEFAULT_TOKEN_EXPIRE_IN) * 1000 - 30 * 1000 // expiresIn unit is seconds

		this.expiresAt = Date.now() + this.expiresIn

		if (accessToken) {
			axios.defaults.headers.common['Authorization'] = `${tokenType === 'bearer' ? 'Bearer' : ''} ${accessToken}`
		} else {
			delete axios.defaults.headers.common['Authorization']
		}
		if (!accessToken) {
			notifyStore.unInitOneSignal()
		}
	}

	@action
	refreshProfile = async () => {
		try {
			if (!this.token) {
				return
			}

			const { data } = await apolloClient.query({
				query: GET_PROFILE_BASIC_INFO_QUERY,
			})
			const { worker } = data

			await this.changeProfile(worker, worker?.notificationChannels[0]?.channel)
		} catch (error) {
			console.debug('🚀 ~ AuthStoreCandidate ~ refreshProfile= ~ error:', error)
		}
	}

	@action
	verifyToken = async () => {
		if (!this.authorization) {
			await this.changeToken({})
			return
		}

		try {
			const {
				data: { workerToken },
			} = await apolloClient.query({
				query: VERIFY_WORKER_QUERY,
				variables: { token: this.authorization },
			})
			const { token, worker, notificationChannel } = workerToken
			if (this.isLogin) {
				this.profile = worker
				await this.changeToken(token)
				await this.changeProfile(worker, notificationChannel)
			}

			return worker
		} catch (error) {
			captureException('Verify token', error)
			// todo: @revert
			await this.changeToken({})
		}
	}

	@action
	refreshAuthToken = async () => {
		if (this.refreshToken) {
			try {
				const variables = { refreshToken: this.refreshToken }
				const {
					data: {
						refreshToken: { authToken },
					},
				} = await apolloClient.mutate({
					mutation: REFRESH_WORKER_TOKEN,
					variables,
					context: {
						clientName: 'public',
					},
				})

				await this.changeToken(authToken)

				return authToken
			} catch (error) {
				return Promise.resolve({})
			}
		} else {
			return Promise.resolve({})
		}
	}

	@event(EVENTS.authStore.logout)
	async logout(callApi = true, shouldShowDialog = true, redirectLink = PATHS.common.authorization) {
		const handleOk = async () => {
			if (callApi) {
				await apolloClient
					.mutate({
						mutation: SIGN_OUT_WORKER_MUTATION,
					})
					.catch((error) => {
						console.debug('🚀 ~ file: auth.store.js:328 ~ AuthStoreCandidate ~ logout ~ error:', error)
					})
			}

			// Create a new BroadcastChannel
			const logoutChannel = new BroadcastChannel('logout_channel')

			// Perform logout actions
			await this.changeToken({})
			await eventClient.setUserId(null)
			await authStore.handleAuthAnonymous(false)
			await authStore.resetPersist()
			await eventClient.setUserProperties(null)
			await careNotificationStore.setNotifications([])
			await careFindJobStore.setInitSearchListList()
			await lastViewedStore.resetCountLastView()
			await getUserTrackingBasicInfor().then((response) => {
				eventClient.setBasicInfo(omit(response, ['userProperties']))
			})
			await commonSigninStore.setCampaigns([])

			// Broadcast the logout message
			logoutChannel.postMessage('logout')

			// Close the channel
			logoutChannel.close()

			// Redirect to the specified link
			await routerStore.goPage(redirectLink)
		}

		if (shouldShowDialog) {
			void notifyStore.open(
				"Please note that signing out will sign you out of your current session, and you'll need to sign in again to access your account.",
				{
					title: i18next.t('LOGOUT_TITLE'),
					showDialog: true,
					keepOriginal: true,
					okText: 'Sign out',
					cancelText: 'Cancel',
					showButtonClose: false,
					style: { maxWidth: '100%' },
					onOk: async (key) => {
						await notifyStore.closeDialog(key)
						await handleOk()
						await notifyStore.success('You signed out successfully')
					},
					onCancel: (key) => {
						notifyStore.closeDialog(key)
					},
				}
			)
		} else {
			void handleOk()
		}
	}

	@action
	setLastJobId = async (workerId, jobId, jobTitle) => {
		if (!isEmpty(workerId)) {
			const doc = fireStore.db.collection(COLECTIONS.last_job_id).doc(workerId)
			await doc.set({ jobId, jobTitle })
		}
	}

	@action
	handleSetShowDialogOtp = (value) => {
		this.showDialogOtp = value
	}

	@action
	handleSetPhoneNumber = (value) => {
		this.phoneNumber = value
	}

	@action
	setShowChangePasswordDialog = (value) => {
		this.showChangePasswordDialog = value
	}

	@action
	setPhoneVerifyAt = (value) => {
		this.phoneVerifyAt = value
	}
	@action
	setShowResetPassLockAccount = (value) => {
		this.showResetPassLockAccount = value
	}
	@action
	setShowResetPassLockContent = (value) => {
		this.showResetPassLockContent = value
	}
	@action
	resetPersist = () => {
		this.expiresAt = 0
		this.profile = null
		this.isExistAccount = false
		this.notificationChannel = ''
		this.currentJob = null
		this.phoneVerifiedAt = null
	}

	@action
	verifyExistEmail = async (variables, { setErrors, setSubmitting }, history) => {
		authStore.resetPersist()
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'signInWorker',
					})
					let updatedVariables = {
						recaptchaToken: token,
						...variables,
					}
					const {
						data: { verifyExistedEmail },
					} = await apolloClient.mutate({
						mutation: VERIFY_EXISTED_EMAIL,
						variables: updatedVariables,
					})

					const { existed } = verifyExistedEmail
					this.isExistAccount = existed

					if (existed) {
						await history.push(PATHS.common.signin, {
							email: variables.email,
						})
					} else {
						await routerStore.openNewPage(`${process.env.REACT_APP_MARKETING_WEB_SITE}/candidate-registration`)
						await setSubmitting(false)
					}

					resolve()
				} catch (error) {
					captureException('Signin', error)
					setErrors({ password: error.message })
					reject(error.message)
				}
			})
		})
	}
}

export const authStore = new AuthStoreCandidate()

reaction(
	() => authStore.token,
	debounce((token, prevToken) => {
		if (token && token !== prevToken) {
			authStore.refreshProfile()
		}
	}, 1000)
)
